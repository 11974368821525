import Header from './componets/header/Header';
import Formcontainer from './componets/form/Formcontainer';
import './App.css'
function App() {
  return (
   
    <>
      <Header/>
      <Formcontainer/>
    </>
  )
}

export default App;
