import React from 'react';
import './Header.css'

const Header = () => {
  return (
      <div className='topbackground '>
          <div className='app-heading-top application-Holder'>
              <h2>TO DO</h2>
          </div> 
    </div>
  )
}

export default Header